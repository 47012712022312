// This is a main JavaScript file that will ll be compiled into /javascripts/site.js
//
// Any JavaScript file in your site folder can be referenced here by using import or require statements.
// Additionally, you can import modules installed via your package.json file.
//
// For example:
// import './fileName'
//
// To learn more, visit https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import
document.addEventListener("DOMContentLoaded",function(){

    if (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    ) {
  
    let observer = new IntersectionObserver((entries) => entries.forEach((entry, id)=>{
      if (entry.isIntersecting) {
        ElementsArr.forEach((e) => {
                navEl[ElementsArr.indexOf(e)].classList.remove("selected");
        });
        navEl[ElementsArr.indexOf(entry.target)].classList.add("selected");
      }
    }), {rootMargin: "19% 0px -80% 0px"});
  

    let navEl = document.getElementsByClassName("nav_el");
    let ElementsArr = [];
    let sections = document.querySelectorAll("section");
    let header = document.getElementsByTagName("header")[0];
    ElementsArr = [header].concat(Array.from(sections));
    ElementsArr.forEach((e) => {
            observer.observe(e)
    });
    }
  });